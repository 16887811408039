<template>
    <div>
      <!-- Start Header Area -->
      <Header />
      <!-- End Header Area -->

    <!-- Start Future Area -->
     <div class="service-area ptb--120 bg_color--5" id="future">
      <v-container>
        <v-row class="mb--30 mt--50">
          <v-col cols="12">
            <div class="section-title">
              <!-- <span class="subtitle">Seid gespannt auf die nächste Phase</span> -->
              <h2 class="heading-title text-center">Teilnahmebedingungen</h2>
              <h4 class="heading-title text-center">Ellavate Competition</h4>
              <p class="description">
                <h5>1. Veranstalter</h5>
                Der Veranstalter des Wettbewerbs ist die Hochschule Trier, Fachbereich Wirtschaft, im Rahmen des Kurses “Gecoachter Gründungsprozess”.

                <br>
                <br>
                <h5>2. Teilnahmeberechtigung</h5>
                Teilnahmeberechtigt sind alle eingeschriebenen Studierenden der Hochschule Trier, die mindestens 18 Jahre alt sind. 
                <br><br>
                Ausgeschlossen sind Studierende, die an der Veranstaltung „Opportunity Recognition“ des Masterstudienganges Business Management Entrepreneurship teilnehmen bzw. teilgenommen haben. 

                <br>
                <br>
                <h5>3. Teilnahme</h5>
                Die Teilnahme erfolgt ausschließlich unter Verwendung der eigenen Hochschul-Emailadresse. Es kann an mehreren Challenges teilgenommen werden.  Es dürfen mehrere Konzepte für die einzelnen Challenges eingereicht werden. Die Teilnahme ist als Einzelperson oder in Gruppen möglich. Ausarbeitungen von Gruppen können nur einmal unter Angabe eines einzelnen Gruppenmitgliedes eingereicht werden. Jedes Konzept kann nur einmal eingereicht werden. Die Teilnahme am Wettbewerb ist kostenlos.
                <br><br>
                Mit der Teilnahme am Wettbewerb werden der Hochschule Trier von den Teilnehmenden die nichtausschließlichen, unbefristeten sowie im Übrigen unbeschränkten Nutzungsrechte an den eingereichten Konzepten eingeräumt.

                <br>
                <br>
                <h5>4. Ablauf des Wettbewerbs</h5>
                Die Teilnehmenden erstellen ausführliche Konzepte für eine oder mehrere der ausgeschriebenen drei Challenges, um die Chance auf das Preisgeld zu haben. Die Einreichung der Konzepte muss bis zum 20. Januar 2025, 23:59 Uhr erfolgen. Für jede der drei Challenges soll ein/e Gewinner/in mit einer Urkunde und einem Preisgeld prämiert werden. 
                <br><br>
                Die Jury behält sich vor, bei unzureichender Qualität der eingereichten Konzepte für einzelnen Challenges auf die Ernennung eines/r Gewinners/in zu verzichten. 

                <br>
                <br>
                <h5>5. Preise</h5>
                Jedes gewinnende Konzept einer der drei Challenges wird mit einem Preisgeld von 500 Euro prämiert.
                <br><br>
                Das Preisgeld wird an die Person ausgezahlt, welche die Ausarbeitung des Konzeptes eingereicht hat. Die einreichende Person hat das empfangene Preisgeld an die etwaigen weiteren Gruppenmitglieder anteilig auszukehren. Die Hochschule Trier übernimmt hierbei keine Haftung für die (anteilige) Auskehrung.

                <br>
                <br>
                <h5>6. Verfügbarkeit und Beendigung des Wettbewerbs</h5>
                Der Wettbewerb kann aufgrund äußerer Umstände beendet werden, ohne dass daraus Ansprüche der Teilnehmenden hergeleitet werden können.  

                <br>
                <br>
                <h5>7. Haftung</h5>
                Der Veranstalter haftet nicht für Schäden, die durch Fehler, Verzögerungen oder Unterbrechungen in der Übermittlung, bei Störungen der technischen Anlagen und des Services, unrichtige Inhalte, Verlust oder Löschung von Daten, Viren oder in sonstiger Weise bei der Teilnahme am Wettbewerb entstanden sind.

                <br>
                <br>
                <h5>8. Datenschutz</h5>
                Für die Teilnahme am Wettbewerb ist die Angabe von persönlichen (personenbezogenen) Daten notwendig. Der/Die Teilnehmer/in versichert, dass die von ihm/ihr gemachten Angaben zur Person, insbesondere Vor-, Nachname, wahrheitsgemäß und richtig sind. Die Kontaktdaten des/r Teilnehmer/in wie Vorname, Nachname, E-Mail-Adresse werden zur Durchführung des Wettbewerbs im Rahmen der gesetzlichen Vorgaben erhoben, verarbeitet und genutzt. Bei einem Gewinn von Preisgeld werden zusätzlich die Kontodaten des/r Gewinners/in erhoben, um das Preisgeld auszahlen zu können. Die Daten der Teilnehmenden werden über 6 Monate nach Durchführung des Wettbewerbs gespeichert, um mögliche Ansprüche der Teilnehmenden prüfen zu können und danach zu löschen. Die Erfassung und Verarbeitung der personenbezogenen Daten dient ausschließlich zur Durchführung des Wettbewerbs und zur Ermittlung der Gewinner. Die personenbezogenen Daten der Teilnehmenden werden ohne Einverständnis weder an Dritte weitergegeben noch diesen zur Nutzung überlassen.
                <br><br>
                Mit der Teilnahme erklären die Teilnehmenden ihr Einverständnis mit dieser  Datenverarbeitung.

                <br>
                <br>
                <h5>9. Anwendbares Recht</h5>
                Es gilt das Recht der Bundesrepublik Deutschland. Der Rechtsweg ist ausgeschlossen.
                
                <br>
                <br>
                <h5>10. Kontakt</h5>
                Bei Fragen oder Beschwerden können Sie sich an folgende Adresse wenden: Hochschule Trier, Fachbereich Wirtschaft, U.Burchard(at)hochschule-trier.de.
                



              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Future Area -->
  
      <!-- Start Footer Area  -->
      <Footer />
      <!-- End Footer Area  -->
    </div>
  </template>
  
  <script>
    import Header from "../../components/ellavate/Header_ext";
    import Slider from "../../components/ellavate/Slider";
    import About from "../../components/ellavate/About";
    import Contact from "../../components/ellavate/Contact";
    import Footer from "../../components/ellavate/Footer";


    // import PortfolioTwo from "../../components/portfolio/PortfolioFour";
    // import PricingPlan from "../../components/pricing-plan/PricingPlan";
  
    export default {
      components: {
        Header,
        Slider,
        About,
        Contact,
        Footer,

        // PortfolioTwo,
        // PricingPlan,
        
      },
      data() {
        return {};
      },
    };
  </script>
  
  <style lang="scss">
    .feather-menu {
      color: #1d1d24;
    }
  </style>
  